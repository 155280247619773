var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":""}},[_c('b-row',{staticClass:"justify-content-between d-flex"},[_c('b-col',[_c('b-row',{staticClass:"ml-2"},[_c('b-form-group',[_c('b-form-input',{attrs:{"type":"text","placeholder":"Buscar item"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-row',[_c('b-button',{staticClass:"mb-2 mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('/novo-projeto')}}},[_vm._v(" Novo item + ")])],1)],1)],1),_c('b-table',{staticClass:"p-1 w-100",attrs:{"responsive":"sm","small":"","items":_vm.filter(),"fields":_vm.fields,"sort-desc":_vm.sortDesc},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(project_code)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_code)+" "),_c('br'),_c('small',{style:({ color: 'red' })},[_vm._v(_vm._s(item.is_archived ? '(Arquivado)' : ''))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('Actions',{attrs:{"item":item},on:{"openUnDeleteModal":function($event){return _vm.openUnDeleteModal(item)},"openDeleteModal":function($event){return _vm.openDeleteModal(item)}}})]}},{key:"cell(finish_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.finish_date ? _vm.dateFormatter(item.finish_date, 'dd/MM/yyyy') : 'indefinido')+" ")]}},{key:"cell(start_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date ? _vm.dateFormatter(item.start_date, 'dd/MM/yyyy') : 'indefinido')+" ")]}},{key:"cell(contract_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contract_date ? _vm.dateFormatter(item.contract_date, 'dd/MM/yyyy') : 'indefinido')+" ")]}}])})],1),_c('b-modal',{attrs:{"title":"Arquivar Projeto","hide-header-close":"","hide-footer":""},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('DeleteModal',{attrs:{"item":_vm.selectedItem},on:{"closeDeleteModal":_vm.closeDeleteModal}})],1),_c('b-modal',{attrs:{"title":"Desarquivar Projeto","hide-header-close":"","hide-footer":""},model:{value:(_vm.unDeleteModal),callback:function ($$v) {_vm.unDeleteModal=$$v},expression:"unDeleteModal"}},[_c('UnDeleteModal',{attrs:{"item":_vm.selectedItem},on:{"closeUnDeleteModal":_vm.closeUnDeleteModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }