<template>
  <div class="actiontdiv">
    <feather-icon
      v-if="item.is_archived"
      v-b-tooltip.hover.lefttop.v-primary
      title="Desarquivar projeto"
      icon="RefreshCwIcon"
      size="15"
      @click="openUnDeleteModal(item)"
    />

    <feather-icon
      v-if="!item.is_archived"
      v-b-tooltip.hover.lefttop.v-primary
      title="Arquivar projeto"
      icon="FolderMinusIcon"
      size="15"
      @click="openDeleteModal(item)"
    />
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Dashboard"
      icon="BarChartIcon"
      size="15"
      @click="$router.push(`/projetos/dashboard/${item.id}`)"
    />
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Editar projeto"
      icon="EditIcon"
      size="15"
      @click="$router.push(`/editar-projeto/${item.id}`)"
    />
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Tarefas do projeto"
      icon="BookOpenIcon"
      size="15"
      @click="$router.push(`/tarefas-projeto/${item.id}`)"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
// import { RefreshCwIcon } from 'vue-feather-icons';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    item: { type: Object, default: () => {} },
  },
  methods: {
    openDeleteModal(item) {
      this.$emit('openDeleteModal', item);
    },

    openUnDeleteModal(item) {
      this.$emit('openUnDeleteModal', item);
    },
  },
};
</script>

<style>
.actiontdiv {
  display: flex;
  gap: 5px;
}
</style>
import { TrashIcon } from 'vue-feather-icons';, RefreshCwIcon
