<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="">
      <b-row class="justify-content-between d-flex">
        <b-col>
          <b-row class="ml-2">
            <b-form-group>
              <b-form-input type="text" v-model="search" placeholder="Buscar item"></b-form-input>
            </b-form-group>
          </b-row>
        </b-col>

        <b-col class="d-flex justify-content-end">
          <b-row>
            <b-button class="mb-2 mr-2" variant="primary" @click="$router.push('/novo-projeto')"> Novo item + </b-button>
          </b-row>
        </b-col>
      </b-row>

      <!-- Tabela principal  -->
      <b-table
        responsive="sm"
        small
        :items="filter()"
        :fields="fields"
        :sort-desc.sync="sortDesc"
        class="p-1 w-100"
      >
        <template v-slot:cell(project_code)="{ item }">
          {{ item.project_code }} <br />
          <small :style="{ color: 'red' }">{{ item.is_archived ? '(Arquivado)' : '' }}</small>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <Actions @openUnDeleteModal="openUnDeleteModal(item)" @openDeleteModal="openDeleteModal(item)" :item="item" />
        </template>
        <template v-slot:cell(finish_date)="{ item }">
          {{ item.finish_date ? dateFormatter(item.finish_date, 'dd/MM/yyyy') : 'indefinido' }}
        </template>
        <template v-slot:cell(start_date)="{ item }">
          {{ item.start_date ? dateFormatter(item.start_date, 'dd/MM/yyyy') : 'indefinido' }}
        </template>
        <template v-slot:cell(contract_date)="{ item }">
          {{ item.contract_date ? dateFormatter(item.contract_date, 'dd/MM/yyyy') : 'indefinido' }}
        </template>
      </b-table>
    </b-card>
    <b-modal v-model="deleteModal" title="Arquivar Projeto" hide-header-close hide-footer>
      <DeleteModal @closeDeleteModal="closeDeleteModal" :item="selectedItem" />
    </b-modal>
    <b-modal v-model="unDeleteModal" title="Desarquivar Projeto" hide-header-close hide-footer>
      <UnDeleteModal @closeUnDeleteModal="closeUnDeleteModal" :item="selectedItem" />
    </b-modal>
  </div>
</template>

<script>
import { BCard, BModal, BRow, BButton, BTable, BCol, BFormInput, BFormGroup } from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';

import DeleteModal from './components/DeleteModal.vue';
import Actions from './components/ActionsProjects.vue';

import UnDeleteModal from './components/UnDeleteModal.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCard,
    BTable,
    BRow,
    BButton,
    Actions,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    DeleteModal,
    UnDeleteModal
  },
  data: () => ({
    search: '',
    sortDesc: false,
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false
      },
      {
        key: 'project_code',
        label: 'Código',
        sortable: true
      },
      {
        key: 'project_name',
        label: 'Nome do projeto',
        sortable: true
      },
      {
        key: 'client_name',
        label: 'Cliente',
        sortable: true
      },
      {
        key: 'contact_name',
        label: 'Contato',
        sortable: true
      },
      {
        key: 'finish_date',
        label: 'Prazo',
        sortable: true
      },
      {
        key: 'contract_date',
        label: 'Data do contrato',
        sortable: false
      },
      {
        key: 'start_date',
        label: 'Dia de início',
        sortable: false
      }
    ],
    deleteModal: false,
    unDeleteModal: false,
    items: [],
    selectedItem: null,
    showArchived: false
  }),

  created() {
    this.getAll();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    }
  },

  methods: {
    // Entidades

    async getAll() {
      this.$store
        .dispatch('_getProjects', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id
        })
        .then(resp => {
          this.items = this.orderByName(resp);
        })
        .catch(err => {
          console.log(err);
        });
    },

    closeDeleteModal() {
      this.deleteModal = false;
      this.getAll();
    },

    closeUnDeleteModal() {
      this.unDeleteModal = false;
      this.getAll();
    },

    openUnDeleteModal(item) {
      this.selectedItem = item;
      this.unDeleteModal = true;
    },

    openDeleteModal(item) {
      this.selectedItem = item;
      this.deleteModal = true;
    },

    filter() {
      if (this.search.trim() === '') {
        // If the search input is empty, return all items
        return this.items;
      } else {
        // If there's a search term, filter the items
        return this.items.filter(item => {
          return (
            (item.project_code && item.project_code.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
            (item.project_name && item.project_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
            (item.client_name && item.client_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
            (item.contact_name && item.contact_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          );
        });
      }
    },

    orderByName(array) {
      return array.sort((a, b) => {
        const nomeA = a.project_name.toUpperCase();
        const nomeB = b.project_name.toUpperCase();
        if (nomeA < nomeB) {
          return -1;
        }
        if (nomeA > nomeB) {
          return 1;
        }
        return 0;
      });
    },

    cleanFilter() {
      this.search = '';
    }
  }
};
</script>
import { filter } from 'core-js/core/array';
